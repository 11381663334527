body {
  background-color: #fff;
  font-family: Arial, Helvetica, sans-serif !important;
  position: relative;
  padding-left: 10vw;
  padding-right: 10vw;
}

.btn-primary {
  background-color: #fff;
  border-color: #0dd9bf;
  border: 2px solid;
  font-size: 16px;
  font-weight: 500;
  color: #0dd9bf;
  padding: 5px 40px;
  text-transform: uppercase;
  border-radius: 24px;
}

.UserStake {
  border: 2px solid lightgray;
  border-radius: 8px;
  padding: 6px;
  margin-bottom: 20px;
}

.StakeContract {
  border: 2px solid lightgray;
  border-radius: 8px;
  padding: 6px;
  margin: 16px 0px;
}

.btn-second {
  border: none;
  font-size: 16px;
  background-color: #ffe0e0;
  color: red;
  padding: 5px 10px;
  border-radius: 8px;
}

.modal-content {
  position: relative;
  background-color: white;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border-radius: 12px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.navbar {
  background-color: #fff;
}

.desktop_nav {
  display: none;
}

.navbar-expand-lg .navbar-toggler {
  display: none;
}

.mobile_nav {
  display: flex;
}

.nav-link {
  padding: 0rem 1rem !important;
}

@media (max-width: 600px) {

  .navbar-expand-lg .navbar-toggler {
    display: flex !important;
  }

  .desktop_nav {
    display: flex !important;
  }

  .mobile_nav_button,
  .mobile_nav {
    display: none !important;
  }

}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}

.error {
  color: red;
  font-size: 15px;
  text-align: left;
  text-transform: capitalize;
  padding-left: 8px;
  margin-top: 4px;
}

p {
  margin-bottom: 0px;
}

.nav-header {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.contractSep {
  color: #797979;
  font-weight: 400;
  size: 14px;
  margin-bottom: 1.5rem;
  text-align: center;
}

.modalheader {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.mb-200 {
  margin-top: 10px;
  margin-bottom: 40px;
}

hr {
  margin-top: 2rem;
  margin-bottom: 0.2rem;
}

.nav-head {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;
}

.stakeButton {
  background-color: black;
  color: white;
  width: 100%;
  border-radius: 8px;
  font-weight: 400;
  size: 18px;
}

.nav-logo {
  width: 200px;
  height: 36px;
}

.modalButton {
  background-color: black;
  color: white;
  width: 40%;
  margin: 2px 8px;
  border-radius: 8px;
}

.claimButton {
  background-color: red;
  color: white;
  width: 100%;
  border: 2px solid red;
  margin: 8px 0;
  border-radius: 8px;
}

.stakeInput {
  color: red;
  width: 100%;
  border-radius: 8px;
  padding: 4px 16px;
  margin: 8px 0;
  display: inline-block;
  border: 2px solid red;
  box-sizing: border-box;
}

.stakeInput::placeholder {
  color: red;
  opacity: 0.8;
}

.adminInput {
  color: black;
  width: 100%;
  border-radius: 8px;
  padding: 4px 16px;
  margin: 8px 0;
  display: inline-block;
  border: 2px solid black;
  box-sizing: border-box;
}

.adminInput::placeholder {
  color: black;
  opacity: 0.8;
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.w-75 {
  width: 75%;
}

.w-25 {
  width: 22% !important;
  margin-left: 2%;
}

@media (max-width: 1140px) {
  body {
    padding-left: 4vw;
    padding-right: 4vw;
  }
}

.connectWalletBox {
  width: 100%;
  margin-bottom: 8px;
  font-weight: 400;
  size: 18px;
}

.connectWalletBox:hover {
  color: red;
}

@media (max-width: 680px) {
  .nav-header {
    display: block;
    text-align: center;
  }

  .footer .img {
    padding: 0px 2vw !important;
  }

  .link-header {
    display: grid;
    margin-top: 0px !important;
  }

  .col-lg-12,
  .col-lg-6,
  .col-md-6,
  .col-xs-6,
  .col-lg-4,
  .col-md-4,
  .col-xs-4,
  .col-md-12,
  .col-xs-12 {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.buy-token {
  border-radius: 1rem;
  color: white;
}

th,
td {
  padding: 4px 16px;
  text-align: center;
}

td {
  border-right: 1px solid #0dd9bf;
}

.fa.fa-eye {
  color: #004dfa !important;
  cursor: pointer;
}

table tr:nth-child(2n) {
  background-image: linear-gradient(90deg, #fff, #ddd) !important;
}

table tr td:nth-child(8) {
  border: none;
}

.navMiddle {
  font-weight: 700;
  font-size: 24px;
}

.box-header {
  font-weight: 700;
  font-size: 18px;
  padding: 0px;
}

.redBold {
  color: red;
  font-weight: 700;
  size: 16px;
}

.date {
  font-size: 14px;
}

.header {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: fantasy;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.title {
  text-align: center;
  color: #fff !important;
}

.footer {
  position: absolute;
  bottom: 0px;
}

.footer img {
  width: 100%;
  height: 42px;
}

.p-0 {
  padding: 0px;
}

.toaster-success {
  border-color: #28a745;
  border: 2px solid;
  color: #28a745;
  padding: 5px 10px;
  border-radius: 8px;
}

.toaster-error {
  border-color: #dc3545;
  border: 2px solid;
  color: #dc3545;
  padding: 5px 10px;
  border-radius: 8px;
}

input>.btn:focus {
  background-color: transparent;
}

input:focus {
  outline: none !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  opacity: 1;
  z-index: auto;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: white;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

select::-ms-expand {
  display: none;
}

.modalheight {
  max-height: 90vh;
  overflow: auto;
}

select {
  cursor: pointer;
}

ul {
  padding: 0;
}

ul li {
  display: inline-block;
  position: relative;
  line-height: 21px;
  text-align: center;
  width: 100%;
}

ul li a {
  display: block;
  padding: 8px 25px;
  color: #0dd9bf;
  text-decoration: none;
}

ul li ul li a,
ul li a:hover {
  color: white;
  text-decoration: none;
}

ul li ul li a:hover {
  background: #0edbbe !important;
}

ul li ul.dropdown {
  min-width: 100%;
  background: #004dfa;
  display: none;
  position: absolute;
  z-index: 999;
  left: 0;
}

ul li:hover ul.dropdown {
  display: block;
}

ul li ul.dropdown li {
  display: block;
}

button ul {
  margin-bottom: 0px;
}

button.dropdown {
  padding: 0px;
}